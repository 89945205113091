(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$http', '$state', '$rootScope', '$timeout'];

    function HomeController($scope, $http, $state, $rootScope, $timeout) {
        var vm = this;
        vm.loadAll = loadAll;

        function loadAll() {
            if ($rootScope.environment == null) {
                $http.get('api/common/_getEnvironment').then(function (result) {
                    $rootScope.environment = result.data;

                    if (result.data == 'IO') {
                        $state.go('home.io');
                    } else if (result.data == 'MZ') {
                        $state.go('home.mz');
                    } else if (result.data == 'SK') {
                        $state.go('home.sk');
                    } else {
                        $state.go('home.fl');
                    }
                });
            } else {
                $timeout(function () {
                    if ($rootScope.environment == 'IO') {
                        $state.go('home.io');
                    } else if ($rootScope.environment == 'MZ') {
                        $state.go('home.mz');
                    } else if ($rootScope.environment == 'SK') {
                        $state.go('home.sk');
                    } else {
                        $state.go('home.fl');
                    }
                }, 0);
            }
        }
        loadAll();
    }
})();
